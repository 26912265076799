/** @jsx jsx */
import React from 'react';
import {jsx, css} from "@emotion/core";
import {GlobalIcons} from "../styles/globalstyles";

let AudioState = (function () {
    let instance;

    function createInstance() {
        return {
            audioPlayer: null
        };
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

const iconButtonStyle = css`
  width: 3rem;
  height: 3rem;
  border: 0;
  cursor: pointer;
  background-color: unset;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
`;

const audioIcon = css`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.12 51.84'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23161615;stroke-width:5px;%7D.cls-2%7Bfill:%23161615;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eaudio%3C/title%3E%3Ccircle class='cls-1' cx='26.06' cy='26.04' r='23.3'/%3E%3Cpath id='Pfad_293' class='cls-2' d='M27.62,26.28c0,2.54,0,5.08,0,7.62a3.42,3.42,0,0,1-.26.88,3.29,3.29,0,0,1-.91-.37c-1.2-1-2.35-2.07-3.57-3a2.3,2.3,0,0,0-1.28-.51c-1.48-.06-3,0-4.46,0-1,0-1.23-.25-1.24-1.18,0-2.16,0-4.32,0-6.48,0-.81.4-1.13,1.15-1.13,1.37,0,2.75,0,4.13,0a2.52,2.52,0,0,0,1.36-.53c1.3-1,2.53-2.2,3.81-3.27a3.67,3.67,0,0,1,1.05-.42,4.48,4.48,0,0,1,.23,1C27.63,21.25,27.62,23.77,27.62,26.28Z'/%3E%3Cpath id='Pfad_294' class='cls-2' d='M34.22,26a6.37,6.37,0,0,0-1.51-3.81l-.27-.31c-.37-.44-.49-.93,0-1.36s.94-.27,1.35.16a7.82,7.82,0,0,1,2,3.66,8,8,0,0,1-1.86,7.38c-.41.47-.91.8-1.46.32s-.3-1,.12-1.49A6.63,6.63,0,0,0,34.22,26Z'/%3E%3Cpath id='Pfad_295' class='cls-2' d='M33,26.3a6,6,0,0,1-1.46,3.77c-.4.44-.88.71-1.41.25s-.32-.94.05-1.42a3.85,3.85,0,0,0,0-5.24c-.35-.47-.64-.94-.1-1.42s1-.2,1.45.27A5.8,5.8,0,0,1,33,26.3Z'/%3E%3C/svg%3E");
  transition: background-image 0.5s;
  ${iconButtonStyle}
`;

const audioIconPlaying = css`
  ${GlobalIcons.pauseIcon}
  transition: background-image 0.5s;
  ${iconButtonStyle}
`;

class AudioPlayer extends React.Component {

    identifier;
    audioRef;

    constructor(props) {
        super(props);
        this.audioRef = React.createRef();

        this.state = {
            isPlaying: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.isPlaying && prevProps.audioFile !== this.props.audioFile) {
            this.setState({
                isPlaying: false
            });
            this.stop();
        }

    }

    render() {
        const srcFile = this.props.audioFile;

        const buttonStyle = this.state.isPlaying ? audioIconPlaying : audioIcon;

        return <div css={css`display:inline-block; justify-content: right;`}>
            <audio ref={this.audioRef} onPlay={this.playHandler} onEnded={this.stopHandler}
                   onPause={this.stopHandler} src={srcFile}/>
            <button aria-label={"Audio abspielen Button"} css={buttonStyle} onClick={this.togglePlay}>
            </button>
        </div>

    }

    togglePlay = () => {

        if(this.state.isPlaying) {
            this.stop();
            AudioState.getInstance().audioPlayer = null;
        } else {

            if(AudioState.getInstance().audioPlayer !== null) {
                AudioState.getInstance().audioPlayer.stop();
            }

            AudioState.getInstance().audioPlayer = this;
            this.start();
        }
    }

    stop = () => {
        const thisAudioElement = this.audioRef.current;
        if(thisAudioElement!==null) {
            thisAudioElement.pause();
            thisAudioElement.currentTime = 0;
        }
    }

    start = () => {
        const thisAudioElement = this.audioRef.current;
        if(thisAudioElement!==null) {
            thisAudioElement.play();
        }
    }

    playHandler = () => {
        this.setState({
            isPlaying: true
        })
    }

    stopHandler = () => {
        this.setState({
            isPlaying: false
        })
    }
}

export default AudioPlayer;